import moment from 'moment';
import React from 'react';
import { LineTradeModel } from '../components/ScheduleByLine/Search/Search';
import { VslOsb } from '../types/dto/ScheduleByLine';
import { appHelper } from './appHelper';
import { stringExtensions } from './stringExtensions';
import { Cookies } from 'react-cookie';
import { env } from '../environments/environment';
const cookies = new Cookies();

export type SchedulePortLink = {
  schedulePortUrl: string;
  countryCode: string;
  portCode: string;
  portName: string;
  exception?: string;
  countryName?: string;
};

export type ScheduleVesselLink = {
  scheduleVesselUrl: string;
  vesselCode: string;
  voyageNo: string;
  vesselName: string;
};

export type ScheduleLineLink = {
  scheduleLineUrl: string;
  trade: string;
  line: string;
  lineName: string;
};

class SchedulesHelper {
  getPortLink = (link: SchedulePortLink) => {
    const currentDate = moment().format('DD-MM-YYYY');
    const weeksAhead = '6';
    const direction = 'False';
    const portLink = `${link.schedulePortUrl}?countrycode=${link.countryCode}&portcode=${link.portCode}&datefrom=${currentDate}&weeksahead=${weeksAhead}&direction=${direction}`;
    const exception = link?.exception === 'Y' ? '*' : '';
    const portLabel = link?.countryName
      ? `${this.stringCapitalLetter(link.portName)}, ${link.countryName} ${exception}`
      : `${this.stringCapitalLetter(link.portName)} ${exception}`;

    return (
      <a
        className="schedule-link"
        href={portLink}
        aria-label={`go to ${link?.portName || 'port name'}`}>
        {portLabel}
      </a>
    );
  };

  stringCapitalLetter = (stringItem: string) => {
    if (stringItem) {
      const portName = stringItem.trim().toLowerCase();
      const newStringName = [];
      portName.split(' ').forEach((item) => {
        if (item.indexOf('(') === 0) {
          if (item.indexOf(')') === 3) {
            newStringName.push(
              item
                .replace(item.charAt(1), item.charAt(1).toUpperCase())
                .replace(item.charAt(2), item.charAt(2).toUpperCase())
            );
          } else {
            newStringName.push(item.replace(item.charAt(1), item.charAt(1).toUpperCase()));
          }
        } else {
          if (item.indexOf('*') === 0) {
            newStringName.push(item.replace(item.charAt(1), item.charAt(1).toUpperCase()));
          } else {
            newStringName.push(item.replace(item.charAt(0), item.charAt(0).toUpperCase()));
          }
        }
      });
      return newStringName.join(' ');
    }
  };

  getVesselLink = (link: ScheduleVesselLink, noCard?: string, useCode?: boolean) => {
    const vesselLink = `${link.scheduleVesselUrl}?vesselcode=${link.vesselCode}&voyageno=${link.voyageNo}`;
    const vesselName = useCode ? `${link.vesselName} (${link.vesselCode})` : link.vesselName;

    return (
      <>
        {vesselName && (
          <a className="schedule-link" href={vesselLink} aria-label={`go to ${vesselName}`}>
            {vesselName}
          </a>
        )}
        {noCard && !vesselName && <>{noCard}</>}
      </>
    );
  };

  getLineLink = (link: ScheduleLineLink) => {
    if (link?.line && link?.scheduleLineUrl && link?.trade && link?.lineName) {
      const lineLink = `${link.scheduleLineUrl}?trade=${link.trade}&line=${link.line}`;
      return (
        <a className="schedule-link" href={lineLink} aria-label={`go to ${link?.lineName}`}>
          {`${link.lineName} (${link.line})`}
        </a>
      );
    } else {
      return null;
    }
  };

  getPartnerVoy = (item: VslOsb, noCard: string) => {
    const partnerVoyList: Array<string> = [];
    item.ports
      .filter((port) => !stringExtensions.isEmptyOrSpaces(port.voyText))
      .map((port) => {
        if (partnerVoyList.indexOf(port.voyText) === -1) {
          partnerVoyList.push(port.voyText);
        }
      });
    if (partnerVoyList.length === 0) {
      return noCard;
    } else {
      return partnerVoyList.map((voyText) => (
        <React.Fragment key={`${item.vslCode}_${voyText}`}>
          {voyText}
          <br />
        </React.Fragment>
      ));
    }
  };

  getCutOffDate = (date: string, portCode: string, noCard: string, checkIfUs = false) => {
    if (date && new Date(date) > appHelper.minDate()) {
      const isUs = checkIfUs ? appHelper.checkIfUsPort(portCode) : false;
      return <>{appHelper.formatDate(date, isUs)}</>;
    } else {
      return <React.Fragment>{noCard}</React.Fragment>;
    }
  };

  setLineMapData = (data: LineTradeModel) => {
    const mapEl = document.querySelector('#map-id');
    if (mapEl) {
      mapEl.setAttribute('data-trade', data.trade);
      mapEl.setAttribute('data-line', data.line);
    }
  };

  setLineDetailsTitle = (data: LineTradeModel) => {
    const titleEl = document.querySelector('#detailsTitle');
    if (titleEl) {
      titleEl.innerHTML = `<span class="pre-ttl">${data?.tradeName}</span>${data?.lineName}`;
    }
  };

  toggleLineTitle = (show: boolean) => {
    const detailsBoxEl = document.querySelector('.line-details-box');
    if (detailsBoxEl) {
      show ? detailsBoxEl.classList.remove('d-none') : detailsBoxEl.classList.add('d-none');
    }
    const mobileMap = document.querySelector('.map-block.mobile-map');
    if (mobileMap) {
      show ? mobileMap.classList.remove('d-none') : mobileMap.classList.add('d-none');
    }
  };

  trigger = (eventType: string, line: string) => {
    const event = new CustomEvent(eventType, { detail: line });
    document.dispatchEvent(event);
  };

  executeLineMap = () => {
    let scriptIsCreated = false;
    return (cb: () => void) => {
      cb();
      if (scriptIsCreated) {
        return;
      }

      const scriptElement = document.createElement('script');
      scriptElement.src = '/static/scripts/line-map.js';
      scriptElement.addEventListener('load', () => {
        const initMapEvent = new Event('init-line-map');
        window.dispatchEvent(initMapEvent);
        scriptIsCreated = true;
      });
      document.body.appendChild(scriptElement);
    };
  };

  getDate = (date: string) => {
    if (date && new Date(date) > appHelper.minDate()) {
      return <>{moment(appHelper.removeGMTfromDate(date)).format('DD-MMM-YYYY')}</>;
    } else {
      return <></>;
    }
  };

  fillPortName = (portName: string, countryName: string) => {
    let result = '';
    if (portName) {
      result = countryName ? `${portName}, ` : portName;
    }
    if (countryName) {
      result = `${result}${countryName}`;
    }
    return result;
  };

  fillVesselVoyage = (vesselName: string, voyage: string, leg: string) => {
    let result = '';
    if (vesselName) {
      result = voyage ? `${vesselName}/` : `${vesselName}`;
    }
    if (voyage) {
      result = leg ? `${result}${voyage}/` : `${result}${voyage}`;
    }
    if (leg) {
      result = `${result}${leg}`;
    }
    return result;
  };

  getZimPartnetVoyage = (zimVoy: number, partnerVoy: string, direction: string, noCard: string) => {
    if (zimVoy === 0 && stringExtensions.isEmptyOrSpaces(direction)) {
      return null;
    }

    return `${zimVoy.toString()}${direction} / ${partnerVoy ?? noCard}`;
  };

  getVesselZimVoyage = (
    zimVoy: number,
    vesselName: string,
    vesselCode: string,
    direction: string,
    noCard: string
  ) => {
    const vesselValue = `${vesselName} (${vesselCode})`;
    let zimVoyage = null;
    if (zimVoy === 0 && stringExtensions.isEmptyOrSpaces(direction)) {
      zimVoyage = noCard;
    } else {
      zimVoyage = `${zimVoy.toString()}${direction}`;
    }
    return `${vesselValue}/${zimVoyage}`;
  };

  submitRedirect(pagePath: string, params: string, withOutLangPath?: boolean) {
    const langPath = cookies.get('langPath');
    const path = `${pagePath}${params}`;
    let urlToRedirect = '';
    if (withOutLangPath) {
      urlToRedirect = `${window.location.origin}/` + path;
    } else {
      urlToRedirect = langPath
        ? `${window.location.origin}/${langPath}/` + path
        : `${window.location.origin}/` + path;
    }
    window.location.href = urlToRedirect;
  }

  checkIfHasException = (vessels: VslOsb[]) => {
    if (!vessels || vessels?.length === 0) {
      return false;
    }

    let hasResult = false;

    vessels.forEach((vessel) => {
      vessel.ports.forEach((port) => {
        if (port.exceptionPort === 'Y') {
          hasResult = true;
        }
      });
    });

    return hasResult;
  };
  getTime = (date: string) => {
    if (date && new Date(date) > appHelper.minDate()) {
      return <>{moment(appHelper.removeGMTfromDate(date)).format('HH:mm A')}</>;
    } else {
      return <></>;
    }
  };
  getTracingLink = (consNumber: string, langPrefix: string, noCard?: string) => {
    const tracingUrl = `${langPrefix}${env.fixed.trackShipment.onSearch}?consnumber=${consNumber}`;
    return (
      <>
        {consNumber && (
          <a className="unit-link" href={tracingUrl} aria-label={`go to ${consNumber}`}>
            {consNumber}
          </a>
        )}
        {noCard && !consNumber && <>{noCard}</>}
      </>
    );
  };
  getCurrentCultureLink(pagePath: string, params: string): string {
    const langPath = cookies.get('langPath');
    const path = `${pagePath}${params}`;
    const urlToRedirect = langPath
      ? `${window.location.origin}/${langPath}/` + path
      : `${window.location.origin}/` + path;

    return urlToRedirect;
  }
}

export const schedulesHelper = new SchedulesHelper();
